import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ChangePasswordDTO } from "../models/change-password-dto";
import { BaseService } from "./base.service";
import { getMainToken, getToken } from "./token-interceptor.service";
import { SimpleEntity } from "../models/simple-entity.model";
@Injectable()
export class UserService extends BaseService<any>{
    constructor(public httpClient: HttpClient, public datepipe: DatePipe) {
        super(
            'user',
            httpClient);
    }

    searchUsers(name: string, transportDirection = null, status = null): Observable<SimpleEntity[]> {

        var token = getToken(transportDirection)
        var url = environment.baseUrl + "/" + this.endpoint + "/searchUsers?token=" + token.token + "&sessionId=" + token.sessionId + "&name=" + name;
        if(transportDirection) url += `&transportDirection=${transportDirection}`
        if(status) url += `&status=${status}`

        return this.httpClient.get<SimpleEntity[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    changePassword(changePasswordDto: ChangePasswordDTO): Promise<any[]> {

        const importToken = localStorage.getItem('import') ? getToken("I") : null;
        const exportToken = localStorage.getItem('export') ? getToken("E") : null;

        console.log(importToken, "importToken")
        console.log(exportToken, "exporttoken")
        let promisesArr = [];

        // domainUrl
        var token = localStorage.getItem("token");
        var sessionId = localStorage.getItem("sessionId");
        const domainUrl = environment.baseUrl + "/" + this.endpoint + "/changePassword?token=" + token + "&sessionId=" + sessionId;
        promisesArr.push(this.httpClient.post<Boolean>(domainUrl, changePasswordDto, { withCredentials: false, headers: this.httpHeaders() }).toPromise());

        //import
        if(importToken) {
            const importUrl = environment.baseUrl + "/" + this.endpoint + "/changePassword?token=" + importToken.token + "&sessionId=" + importToken.sessionId + "&transportDirection=I"
            promisesArr.push(this.httpClient.post<Boolean>(importUrl, changePasswordDto, { withCredentials: false, headers: this.httpHeaders() }).toPromise());
        }
        //export
        if(exportToken) {
            const exportUrl = environment.baseUrl + "/" + this.endpoint + "/changePassword?token=" + exportToken.token + "&sessionId=" + exportToken.sessionId + "&transportDirection=E";
            promisesArr.push(this.httpClient.post<Boolean>(exportUrl, changePasswordDto, { withCredentials: false, headers: this.httpHeaders() }).toPromise());
        }

        return Promise.all(promisesArr);

    }

    getFirstByIsActiveIsTrue(transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/user/getFirstByIsActiveIsTrue';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, transportDirection});

        return this.httpClient.get<any[]>(url, {params: options, observe: 'response'});
    }

    getUserProfile(id?: number, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/user';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, id, transportDirection});

        return this.httpClient.get<any[]>(url, {params: options, observe: 'response'});
    }

    updateUserProfile(user: any, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/user';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, transportDirection});

        return this.httpClient.put<any[]>(url, user, {params: options, observe: 'response'});
    }

    getUserFromLocalStorage(key = 'user') {
        if(!localStorage.getItem(key)) return;
        return JSON.parse(localStorage.getItem(key));
    }

    function(name: string, transportDirection?: string): Observable<Boolean> {
        const token = getToken(transportDirection);
        const url = environment.baseUrl + '/user/function';
        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId,  transportDirection, name});
        return this.httpClient.get<boolean>(url,{ withCredentials: false, headers: this.httpHeaders(), params: options })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    getUserByTransportDirection(transportDirection) {
        if(transportDirection == 'I' && localStorage.getItem('import')) {
            const usr = JSON.parse(localStorage.getItem('import')).user;
            return Object.assign(usr, {name: `${usr.firstName} ${usr.lastName}`})
        }
        if(transportDirection == 'E' && localStorage.getItem('export')) {
            const usr = JSON.parse(localStorage.getItem('export')).user;
            return Object.assign(usr, {name: `${usr.firstName} ${usr.lastName}`})
        }

        const usr =  JSON.parse(localStorage.getItem('user'))
        return Object.assign(usr, {name: `${usr.firstName} ${usr.lastName}`})
    }

    forgotPassword(username: string, path: string) {

        var url = environment.baseUrl + "/user/forgotPassword?username=" + username + "&path=" + path;

        return this.httpClient.get<Boolean>(url, { withCredentials: false, headers: this.httpHeaders() })
    }
    changePasswordMail(changePasswordDto: ChangePasswordDTO, token: string, username: string) {

        var url = environment.baseUrl + "/user/changePasswordMail?token=" + token + "&username=" + username;

        return this.httpClient.post<Boolean>(url, changePasswordDto, { withCredentials: false, headers: this.httpHeaders() })
    }

    changePasswordUser(isOutOfOffice: boolean, outOfOfficeDescription: string, transportDirection?: string): Observable<Boolean> {
        const token = getToken(transportDirection);
        const url = environment.baseUrl + '/user/changePasswordUser';
        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId,  transportDirection, isOutOfOffice, outOfOfficeDescription});
        return this.httpClient.get<boolean>(url,{ withCredentials: false, headers: this.httpHeaders(), params: options })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }
    //TODO: impersonation !!!
}
